import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-cargando',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatProgressSpinnerModule],
  template: `
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  `,
  styles: `
    .spinner {
        width: 100%;
        height: auto;
        min-height: 500px;
        // background: rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }    
    `
})
export class CargandoComponent {

}
