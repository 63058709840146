import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AngularModule, MaterialModule } from '@shared/modules';

@Component({
  selector: 'app-spinner',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AngularModule,
    MaterialModule,
  ],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {

}
