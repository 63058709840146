import { ChangeDetectorRef, Component, Input, OnInit, inject, input, signal } from '@angular/core';

import { AngularModule, MaterialModule } from '@shared/modules';
import { TableService } from '../table/table.service';

@Component({
  selector: 'app-header-abm',
  standalone: true,
  templateUrl: './header-abm.component.html',
  styleUrl: './header-abm.component.scss',
  imports: [
    AngularModule,
    MaterialModule,
  ],
})
export class HeaderAbmComponent implements OnInit{

  title = input.required();
  @Input() button = signal<any>('');
  @Input() enableButton: boolean = true;
  path = input.required();

  // ========== Description ==========
  headerName = input<string>('');
  description = input<any>('');
  pipe = input<string>('|');

  showSpinner = signal<boolean>(false);

  // ========== DEPENDENCIES INJECTION ==========
  private readonly _cd = inject(ChangeDetectorRef);
  private readonly _tableSvc = inject(TableService);

  ngOnInit(): void {
    this._tableSvc.spinner$.subscribe((showSpinner: boolean) => {
      this.showSpinner.set(showSpinner);
      this._cd.detectChanges();
    });
  }


}
