import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AngularModule, MaterialModule } from '@shared/modules';

@Component({
  selector: 'app-input-email',
  standalone: true,
  imports: [
    AngularModule,
    MaterialModule,
  ],
  templateUrl: './input-email.component.html',
  styleUrl: './input-email.component.scss'
})
export class InputEmailComponent {

  @Input() label: string = '';
  @Input() control!: FormControl;
  @Input() isClear: boolean = true;

  @Input() maxLength: number = 0;

  @Output() clearInput: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  clear(): void {
    this.control.setValue('');
    this.clearInput.emit();
  }

  limitInputLength(event: KeyboardEvent): void {

    this.esCaracter(event);

    const currentValue = this.control.value;
    const maxLengthReached = currentValue.length >= this.maxLength;

    if (maxLengthReached) {
      const truncatedValue = currentValue.slice(0, this.maxLength);
      this.control.setValue(truncatedValue);
    }
  }


  // esCaracter(event: KeyboardEvent): void {
  //   const inputValue = this.control.value;
  //   const charCode = event.key.charCodeAt(0);

  //   // Expresión regular para caracteres alfanuméricos, incluyendo números y acentuados
  //   const allowedCharacters = /^[a-zA-ZÀ-ÿ\u00f1\u00d10-9]$/;

  //   // Condición para permitir caracteres válidos y espacios después de un carácter válido
  //   const isValidCharacter = allowedCharacters.test(event.key) || (
  //     charCode === 32 && inputValue.length > 0 && /[a-zA-ZÀ-ÿ\u00f1\u00d10-9]$/.test(inputValue)
  //   );

  //   // Prevenir el comportamiento predeterminado si no se cumple la condición
  //   if (!isValidCharacter) {
  //     event.preventDefault();
  //   }

  //   // Eliminar caracteres no permitidos y controlar espacios múltiples
  //   let sanitizedValue = inputValue.replace(/[^a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]/g, '');

  //   // Reemplazar múltiples espacios consecutivos con un solo espacio
  //   sanitizedValue = sanitizedValue.replace(/\s+/g, ' ');

  //   // Eliminar espacios en blanco iniciales
  //   const trimmedValue = sanitizedValue.trimStart();
  //   this.control.setValue(trimmedValue, { emitEvent: false });
  // }

  esCaracter(event: KeyboardEvent): void {
    const inputValue = this.control.value;
    const charCode = event.key.charCodeAt(0);
  
    // Expresión regular para caracteres permitidos en un email (alfanuméricos, puntos, guiones bajos, números y @)
    const allowedCharacters = /^[a-zA-Z0-9._@]$/;
  
    // Condición para permitir caracteres válidos
    const isValidCharacter = allowedCharacters.test(event.key);
  
    // Prevenir el comportamiento predeterminado si no se cumple la condición
    if (!isValidCharacter) {
      event.preventDefault();
    }
  
    // Eliminar caracteres no permitidos y controlar espacios múltiples
    let sanitizedValue = inputValue.replace(/[^a-zA-Z0-9._@]/g, '');
  
    // Eliminar espacios en blanco iniciales
    const trimmedValue = sanitizedValue.trimStart();
  
    // Validar que es un email válido
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmailValid = emailPattern.test(trimmedValue);
  
    if (!isEmailValid) {
      // Puedes manejar el error de email inválido aquí, por ejemplo, mostrando un mensaje de error
    }
  
    this.control.setValue(trimmedValue, { emitEvent: false });
  }
  

}

