import { Injectable } from '@angular/core';
import { AccionTablaInterface } from './table-actions.interface';
import { BehaviorSubject, Observable } from 'rxjs';

interface AccionesConfig {
  sectionsFn?: Function;
  visualizarFn?: Function;
  editarFn?: Function;
  eliminarFn?: Function;
  otrasAcciones?: {
    key: string;
    event: Function;
    icon: string;
    tooltip: string;
    isSections: boolean;
    isVisualizar: boolean;
    isDelete: boolean;
    isEdit: boolean;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private createAccion(key: string, event: Function, icon: string, tooltip: string, overrides: Partial<AccionTablaInterface> = {}): AccionTablaInterface {
    return {
      key,
      event,
      icon,
      tooltip,
      isSections: false,
      isVisualizar: false,
      isDelete: false,
      isEdit: false,
      ...overrides
    };
  }

  getAcciones(config: AccionesConfig): AccionTablaInterface[] {
    const {
      sectionsFn,
      visualizarFn,
      editarFn,
      eliminarFn,
      otrasAcciones = []
    } = config;

    const acciones: AccionTablaInterface[] = [];

    if (sectionsFn) {
      acciones.push(
        this.createAccion(
          'Secciones',
          sectionsFn,
          'repartition',
          'Circuito',
          { isSections: true }
        )
      );
    }

    if (visualizarFn) {
      acciones.push(
        this.createAccion(
          'Visualizar',
          visualizarFn,
          'visibility',
          'Visualizar',
          { isVisualizar: true }
        )
      );
    }

    if (editarFn) {
      acciones.push(
        this.createAccion(
          'Editar',
          editarFn,
          'edit',
          'Editar',
          { isEdit: true }
        )
      );
    }

    if (eliminarFn) {
      acciones.push(
        this.createAccion(
          'Eliminar',
          eliminarFn,
          'delete',
          'Eliminar',
          { isDelete: true }
        )
      );
    }

    otrasAcciones.forEach(accion => {
      acciones.push(this.createAccion(
        accion.key,
        accion.event,
        accion.icon,
        accion.tooltip,
        {
          isSections: accion.isSections ?? false,
          isVisualizar: accion.isVisualizar ?? false,
          isDelete: accion.isDelete ?? false,
          isEdit: accion.isEdit ?? false
        }
      ));
    });

    return acciones;
  }

  // ========== Sevices ==========
  private paginate: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  dataPaginate$: Observable<any> = this.paginate.asObservable();

  getPagination(): any { return this.paginate.getValue(); }
  setPagination(data: any): void { this.paginate.next(data); }

  private spinner: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  spinner$: Observable<any> = this.spinner.asObservable();

  getSpinner(): any { return this.spinner.getValue(); }
  setSpinner(data: any): void { this.spinner.next(data); }

  private filter: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  datafilter$: Observable<any> = this.filter.asObservable();

  getFilter(): any { return this.filter.getValue(); }
  setFilter(data: any): void { this.filter.next(data); }


}
