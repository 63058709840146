<!-- <mat-form-field appearance="outline">
  <mat-label>{{ defaultValue()? defaultValue: label }}</mat-label>
  <mat-select [formControl]="control">
    <mat-option *ngFor="let option of defaultOptions()" [value]="option[value()]">
      {{ option[labelSelect()] }}
    </mat-option>
  </mat-select>
</mat-form-field> -->

<mat-form-field appearance="outline">
  <mat-label>{{ defaultValue() ? defaultValue() : label() }}</mat-label>
  <mat-select [formControl]="control()">
    @for (option of defaultOptions(); track $index) {
    <mat-option [value]="option[value()]">
      {{ option[labelSelect()] }}
    </mat-option>
    }
  </mat-select>
</mat-form-field>