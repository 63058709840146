<header>
  <div class="contentHeader">
    
    <h3>{{ title() }} </h3>

    @if (showSpinner()) {
      @if(enableButton) {
        <div class="contenedorBoton">
          <button class="btnValid" [routerLink]="[path()]">{{ button() }}</button>
        </div>
      }
  }
  </div>

  <p> {{ headerName() }} &nbsp; @if (headerName().length > 1) { {{ pipe() }} &nbsp; } {{ description() }} </p>

</header>