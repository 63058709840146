import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaterialModule, AngularModule } from '@shared/modules';



@Component({
  selector: 'app-input-textarea',
  standalone: true,
  imports: [
    AngularModule,
    MaterialModule,
  ],
  templateUrl: './input-textarea.component.html',
  styleUrl: './input-textarea.component.scss'
})
export class InputTextareaComponent {

  myFormControl = new FormControl('');

  label = input.required<string>();
  control = input<FormControl>(new FormControl(this.myFormControl));
  maxLength = input.required<number>();
  minLength = input.required<number>();
  isClear = input.required<boolean>();

  @Output() clearInput: EventEmitter<void> = new EventEmitter<void>();
  @Output() minLengthError: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public limitInputLength(event: KeyboardEvent): void {

    this.validateCharacter(event);

    // const currentValue = this.control.value;
    const currentValue = this.control().value;
    const maxLengthReached = currentValue.length >= this.maxLength;

    if (maxLengthReached) {
      const truncatedValue = currentValue.slice(0, this.maxLength);
      this.myFormControl.setValue(truncatedValue);
    }

    const minLengthNotReached = currentValue.length < this.minLength();
    if (minLengthNotReached) {
      this.minLengthError.emit();
    }

  }

  private validateCharacter(event: KeyboardEvent): void {
    const key = event.key;
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const cursorPosition = input.selectionStart || 0;

    // Función para verificar si un carácter es válido
    const isValidChar = (char: string): boolean => {
      const validChars = /^[0-9a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]$/;
      return validChars.test(char);
    };

    // Manejar el caso de espacio
    if (key === ' ') {
      // No permitir espacio al inicio
      if (cursorPosition === 0 || currentValue.length === 0) {
        event.preventDefault();
        return;
      }
      // No permitir múltiples espacios
      if (currentValue[cursorPosition - 1] === ' ') {
        event.preventDefault();
        return;
      }
      // Permitir un solo espacio después de un carácter válido
      return;
    }

    // Verificar otros caracteres
    if (!isValidChar(key)) {
      event.preventDefault();
    }
  }

}
