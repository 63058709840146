import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AngularModule, MaterialModule } from '@shared/modules';

@Component({
  selector: 'app-input-number',
  standalone: true,
  imports: [
    AngularModule,
    MaterialModule
  ],
  templateUrl: './input-number.component.html',
  styleUrl: './input-number.component.scss'
})
export class InputNumberComponent {

  label = input.required<string>();
  // control = input.required<FormControl>();
  @Input() control!: FormControl;
  @Input() isClear: boolean = true;

  minLength = input<number>(0);
  maxLength = input<number>(Infinity);

  @Output() clearInput: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  clear(): void {
    this.control.setValue('');
    this.clearInput.emit();
  }

  esNumero(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  onInputChange(): void {
    const currentValue = this.control.value;
    if (currentValue && currentValue.length > this.maxLength) {
      this.control.setValue(currentValue.slice(0, this.maxLength));
    }
  }
}
