import { Component, input, ChangeDetectionStrategy, signal } from '@angular/core';
import { AngularModule, MaterialModule } from '@shared/modules';

@Component({
  selector: 'app-go-back',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AngularModule,
    MaterialModule,
  ],
  templateUrl: './go-back.component.html',
  styleUrl: './go-back.component.scss'
})
export  class GoBackComponent {

  redirectTo = input<string>('');
  breadcrumbs = input<string>('');
  title = input<string>('');

  // isBtn = input<boolean>();

  // clickEvent = signal<string>('');

  // changeAction(action: string): void {
  //   this.clickEvent.set(action);
  // }
  
}
