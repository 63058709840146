import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, computed, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AngularModule, MaterialModule } from '@shared/modules';

@Component({
  selector: 'app-input-date',
  standalone: true,
  imports: [
    AngularModule,
    MaterialModule
  ],
  templateUrl: './input-date.component.html',
  styleUrl: './input-date.component.scss'
})
export class InputDateComponent {

  label = input.required<string>();
  control = input<FormControl>(new FormControl());
  minDate = input<Date>(new Date());
  maxDate = input<Date>(new Date(new Date().setDate(new Date().getDate() + 8)));

  controlErrors = computed(() => this.control().errors);

}
