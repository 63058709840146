
<main class="card mt-15">
  <section class="cardHeaderTable animated">
    <h3>{{titulo}}</h3>

  </section>

  @if (isSpinner()) {
  <section class="sectionPagination">
    <div class="paginacion">

      <div class="boxSelectOpt">
        <p>Filas por hoja</p>
        <select class="formSelect" [(ngModel)]="itemsPorPagina" (change)="onSelectChange($event)">
          @for (option of itemsPorPaginaSelect; track $index) {
            <option [value]="option">{{ option }}</option>
          }
        </select>
      </div>

      <div class="spanPaginaton">
        @if (paginaActual > 1) {
        <div class="d-flex">
          <span (click)="irPaginaInicial()">
            <mat-icon fontIcon="keyboard_double_arrow_left"></mat-icon>
          </span>

          <span (click)="irPaginaAnterior()">
            <mat-icon fontIcon="arrow_back_ios"></mat-icon>
          </span>

        </div>
        }

        <div class="valuePagination">
          <p>Pág. {{ paginaActual }} de {{ totalPaginas }}</p>
        </div>

        @if (paginaActual < totalPaginas ) { <span (click)="irPaginaSeguiente()">
          <mat-icon fontIcon="arrow_forward_ios"></mat-icon>
          </span>

          <span (click)="irPaginaUltima()">
            <mat-icon fontIcon="keyboard_double_arrow_right"></mat-icon>
          </span>
          }
      </div>
    </div>
  </section>
  }

  @if (isSpinner()) {
  <section class="boxTable">

    <table class="tabla animated">
      <thead>
        <tr (click)="clickAction()">

          <th class="acciones">Acciones</th>

          @for(col of columnsHeaderTable; track col) {
          <th scope="col" style="border-top: 1px solid #ccc;">
            @if(col !== 'id') {
            <div class="headerColumns" (click)="orderBy(col)">
              {{ col | titlecase }}
              @if (sortedColumn === col && sortDirection === 'asc') {
              <mat-icon fontIcon="north"></mat-icon>
              } @else if (sortedColumn === col && sortDirection === 'desc') {
              <mat-icon fontIcon="south"></mat-icon>
              } @else {
              <mat-icon fontIcon="north"></mat-icon>
              }
            </div>
            }
          </th>
          }
        </tr>

        <tr (click)="clickAction()">
          <th style="border-right: 1px solid #ccc; width: 150px;"></th>
       
          @for (item of columnsHeaderTable; track $index;) {
          <th>
            @if (item !== 'id') {
            <form [formGroup]="filterTableForm">
              <div class="searchForm">
                <input matInput [formControlName]="dynamicFormControlName($index)" placeholder="Buscar">
                <mat-icon matTooltip="Buscar" fontIcon="search" (click)="search(item)"></mat-icon>
              </div>
              <!-- <mat-form-field>
              <input matInput [matDatepicker]="fechaDesde">
              <mat-datepicker-toggle matSuffix [for]="fechaDesde"></mat-datepicker-toggle>
              <mat-datepicker #fechaDesde></mat-datepicker>
              </mat-form-field> -->

            </form>
            }
          </th>
          }
        </tr>

      </thead>
      @if(listDataTable.length > 0) {
      <tbody>

        @for(dato of listDataTable; track dato.id ) {

        <tr>
          <td class="iconsTable acciones">

            <!-- <div class="boxIconsTable"></div> -->

            @for(ac of acciones; track ac){

            @if(ac.isSections){
            <span [matTooltip]="ac.tooltip ?? ''" (click)="ac.event(dato)">
              <mat-icon [fontIcon]="ac.icon ?? ''"></mat-icon>
            </span>
            }

            @if(ac.isVisualizar){
            <span [matTooltip]="ac.tooltip ?? ''" (click)="ac.event(dato)">
              <mat-icon [fontIcon]="ac.icon ?? ''"></mat-icon>
            </span>
            }

            @if(ac.isEdit){
            <span matTooltip="Editar" (click)="ac.event(dato)">
              <mat-icon [fontIcon]="ac.icon ?? ''"></mat-icon>
            </span>
            }

            @if(ac.isDelete){
            <span matTooltip="Borrar" (click)="ac.event(dato)">
              <mat-icon [fontIcon]="ac.icon ?? ''"></mat-icon>
            </span>
            }
            }

          </td>

          @for (item of columnsHeaderTable; track $index) {
          <td>
            @if (item !== 'id') {
            {{ dato[item] }}
            }
          </td>
          }
        </tr>

        }

      </tbody>
      }
    </table>

  </section>
  } @else {
  <app-cargando />
  }

</main>