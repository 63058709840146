import { ChangeDetectorRef, Component, Input, OnInit, inject, signal, SimpleChanges, computed, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

import { AngularModule, MaterialModule } from '@shared/modules';

import { TableService } from './table.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CargandoComponent } from '../cargando/cargando.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    AngularModule,
    MaterialModule,
    SpinnerComponent,
    CargandoComponent
  ]
})
export class TableComponent implements OnInit, OnChanges {

  datosOriginales: any[] = []
  lastKey: string = ''
  isOrderIcon: boolean = false

  @Input() titulo: string = '';
  @Input() columnsHeaderTable: string[] = [];
  @Input() listDataTable: any[] = [{}];
  @Input() totalPaginas: number = 0;
  @Input() keyFilter: string[] = [];
  @Input() clickAction: Function = () => { };
  @Input() nameBtn: string = '';
  @Input() acciones: {
    key: string,
    event: Function,
    icon?: string | undefined,
    tooltip?: string | undefined,
    isClick?: boolean | undefined,

    isEdit?: boolean | undefined,
    isDelete?: boolean | undefined,
    isVisualizar?: boolean | undefined,
    isSections?: boolean | undefined,

  }[] = [];

  @Input() accionOrder: Function = () => { };

  public isSpinner = signal<boolean>(true);
  searchFormFilterOriginal: any[] = [];
  searchFormFilter: any[] = [];

  // ===== Estados boton filtrar columnas ======
  searchFormFilterColumn: any[] = []
  removedColumnsSet: string[] = []
  checkColumnsOriginal: any[] = []
  listDataColumns: any[] = []
  checkColumnsFilter: any[] = []

  // ========== Paginación ==========
  listarAlertado: any[] = [];
  @Input() itemsPorPagina!: number;
  itemsPorPaginaSelect: number[] = [5, 10, 25, 50, 100]
  paginaActual = 1;

  isCargando: boolean = false;
  isShowColumn: boolean = false;
  isDataTable: boolean = true;
  isLoading = signal<boolean>(true);

  sortedColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  noExisteTabla: boolean = true;

  public filaPorHojaForm!: FormGroup;
  //  =============== dependency injection ===============

  filterTableForm!: FormGroup;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private readonly _fb = inject(FormBuilder);
  private readonly _cd = inject(ChangeDetectorRef);
  private readonly _tableSvc = inject(TableService);

  public loading = computed(() => this.isLoading());

  ngOnInit() {
    setTimeout(() => this.cargarData({ value: this.itemsPorPagina }), 500);
  }

  ngAfterViewInit(): void {
    this._cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { totalPages } = this._tableSvc.getPagination()
    this.totalPaginas = totalPages;

    this._tableSvc.spinner$.subscribe((state: any) => {
      this.isSpinner.set(state);
    });
  }

  cagarPaginacion(): void {
    const { number, totalPages } = this._tableSvc.getPagination()
    this.paginaActual = number + 1
    this.totalPaginas = totalPages
  }

  private cargarData(value: any): any {
    const dataResp = this.listDataTable;

    if (dataResp.length <= 0 || dataResp === null || dataResp === undefined) {
      return this.isDataTable = false;
    };

    this.datosOriginales = [...dataResp]
    this.paginarListar(value);

    const cloneKeys = Object.keys(this.listDataTable[0]);
    if (cloneKeys.includes('id')) {
      this.lastKey = 'id';
    } else {
      this.lastKey = cloneKeys[0];
    }
    const keys = Object.keys(dataResp[0]);
    const deleteId = keys.filter((item: any) => item !== 'id');

    this.columnsHeaderTable = deleteId;
    this.keyFilter = deleteId;
    this.searchFormFilter = [...deleteId];
    this.searchFormFilterOriginal = [...deleteId];

    this.cargarItemsColumn(keys);
    this.initFilterTable();
    this._cd.detectChanges();
    this.cagarPaginacion();
  }

  private cargarItemsColumn(keyData: any): void {

    const addAllkeys = ['Todos', ...keyData];
    const checkColumns = addAllkeys
      .map((e: any, index: any) => {
        return {
          id: index,
          title: e,
          checked: true,
        };
      });
    this.checkColumnsOriginal = checkColumns;
    this.listDataColumns = checkColumns;
    this.checkColumnsFilter = checkColumns;
  }

  toggleCheckbox(event: any, itemColums: any): void {
    const { title, id } = itemColums;
    const checked = event.target.checked;
    if (title === 'Todos') {
      this.toggleAllCheckboxes(checked);
    } else {
      const updatedColumns = this.listDataColumns
        .map((column: any) => {
          if (column.id === id) {
            column.checked = checked;
          }
          return column;
        });
      this.listDataColumns[0].checked = this.checkAllTrue(updatedColumns)
      this.updateColumns(updatedColumns);
    }
    this._cd.detectChanges();
  }

  private checkAllTrue(array: any[]) {
    for (let i = 1; i <= array.length; i++) {
      if (!array[i].checked)  return false;
    }
    return true;
  }

  private toggleAllCheckboxes(checked: boolean): void {
    const updatedColumns = this.listDataColumns
      .map((column: any) => ({
        ...column,
        checked: checked
      }));

    this.updateColumns(updatedColumns);
  }

  private updateColumns(updatedColumns: any[]): void {
    this.listDataColumns = updatedColumns;
    this.checkColumnsFilter = updatedColumns;
    this.filterColumns(updatedColumns);
  }

  private filterColumns(updatedColumns: any[]): void {
    const deleteTitleTodos = updatedColumns.filter((item: any) => item.title !== 'Todos');

    const titlesTrue = deleteTitleTodos.filter((element: any) => element.checked).map((item: any) => item.title);
    this.columnsHeaderTable = titlesTrue;
  }

  private initFilterTable(): void {
    const formGroupConfig: { [key: string]: any } = {};
    const keys = Object.keys(this.listDataTable[0])
    keys.forEach(key => formGroupConfig[key] = new FormControl(''))
    this.filterTableForm = this._fb.group(formGroupConfig);
  }

  private clearOtherFilters(): void {
    const filterKeys = Object.keys(this.filterTableForm.controls);
    filterKeys.forEach((key: any) => {
      this.filterTableForm.get(key)?.setValue('', { emitEvent: false });
    });
  }

  dynamicFormControlName(index: number): string {
    const fields = this.keyFilter;
    return fields[index];
  }

  irPaginaSeguiente() {
    const data = this._tableSvc.getPagination()
    this.paginaActual++;
    this._tableSvc.setPagination({ ...data, number: this.paginaActual - 1 });
  }

  irPaginaAnterior() {
    const data = this._tableSvc.getPagination()
    this.paginaActual--;
    this._tableSvc.setPagination({ ...data, number: this.paginaActual - 1 });
  }

  irPaginaInicial(): void {
    const data = this._tableSvc.getPagination()
    this.paginaActual = 1;
    this._tableSvc.setPagination({ ...data, number: 0 });
  }

  irPaginaUltima(): void {
    const data: any = this._tableSvc.getPagination()
    this.paginaActual = data.totalPages
    this._tableSvc.setPagination({ ...data, number: this.paginaActual - 1 });
  }

  paginarListar(valor: any) {
    const data: any = this._tableSvc.getPagination();
    this.paginaActual = 1;
    this.itemsPorPagina = Number(valor.value);
    this._tableSvc.setPagination({ ...data, number: this.paginaActual - 1, size: this.itemsPorPagina });
  }

  onSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    this.paginarListar(selectElement);
  }

  orderBy(column: string) {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }

    this.listDataTable.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });

    this._cd.detectChanges();
  }

  descargarExcel(): void {

    const hojaNombre = 'Datos';
    const fechaActual = new Date();
    const fechaFormateada = this.obtenerFormatoFecha(fechaActual);
    const horaFormateada = this.obtenerFormatoHora(fechaActual);

    const nombreArchivo = `system-nvctech-user-${fechaFormateada}-${horaFormateada}.xlsx`;

    const datosExportar = this.datosOriginales.map((item: any) => {
      const fila: any = {};

      Object.keys(item).forEach((key) => {
        if (key !== 'id') {
          fila[this.capitalizeFirstLetter(key)] = item[key];
        }
      });

      return fila;
    });

    const ws = XLSX.utils.json_to_sheet(datosExportar);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, hojaNombre);

    XLSX.writeFile(wb, nombreArchivo);

    XLSX.utils.book_append_sheet(wb, ws, hojaNombre);

  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private obtenerFormatoFecha(fecha: Date): string {
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    return `${dia}/${mes}/${anio}`;
  }

  private obtenerFormatoHora(fecha: Date): string {
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minuto = fecha.getMinutes().toString().padStart(2, '0');
    const segundo = fecha.getSeconds().toString().padStart(2, '0');
    return `${hora}:${minuto}:${segundo}`;
  }

  search(item: any): void {
    const data = this.filterTableForm.getRawValue();
    this.paramsDataSearch(data);
    this._tableSvc.setFilter(data)
    this.clearOtherFilters();
    this.paginaActual = 1;
  }

  paramsDataSearch(data: any): void {
    this._tableSvc.setFilter(data);
    this.paginaActual = 1;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}

