<!-- <mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControl]="control" type="text" (keypress)="esNumero($event)">
  <button *ngIf="control.value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field> -->

<mat-form-field appearance="outline">
  <mat-label>{{ label() }}</mat-label>

  <input matInput 
      [formControl]="control" 
      (keypress)="esNumero($event)" 
      (input)="onInputChange()"
  >
  
  @if (control.value && isClear === true) {
  <button matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
}
</mat-form-field>


