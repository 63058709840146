<header class="headerPage">

  <div class="goBack">
    <span routerLink="{{ redirectTo() }}">
      <mat-icon class="icono">arrow_back</mat-icon> Volver
    </span>
    <p>{{ breadcrumbs() }}</p>
  </div>

  <h3>{{ title() }}</h3>
  <!-- <div class="boxActions">
    @if (isBtn()) {
      <button class="btnCancelar" (click)="changeAction('')">Editar</button>
    }
  </div> -->

</header>