import { Component, EventEmitter, input, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AngularModule, MaterialModule } from '@shared/modules';

export interface Option {
  id: string | number;
  [key: string]: any;
}

@Component({
  selector: 'app-input-select',
  standalone: true,
  imports: [
    AngularModule,
    MaterialModule
  ],
  templateUrl: './input-select.component.html',
  styleUrl: './input-select.component.scss'
})
export class InputSelectComponent implements OnInit {

  label = input<string>('');
  labelSelect = input<string>('');
  value = input<string>('');
  defaultValue = input<string>('');
  defaultOptions = input<Option[]>([]);
  control = input<FormControl>(new FormControl());
  
  @Output() clearInput: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    this.control().valueChanges.subscribe((value: string) => {
      console.log(value);
      // this.control().setValue(value, { emitEvent: false });
    });
  }

  clear(): void {
    this.control().setValue('');
    this.clearInput.emit();
  }
  
}
