<mat-form-field appearance="outline">
  <mat-label>{{ label() }}</mat-label>
  <input 
      matInput 
      [matDatepicker]="picker" 
      [formControl]="control()" 
      [min]="minDate()" 
      [max]="maxDate()"
  >

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  
  <mat-datepicker #picker></mat-datepicker>

  <mat-error *ngIf="controlErrors() && controlErrors()?.['required']">
    {{ label() }} es requerido
</mat-error>

</mat-form-field>
